<template>
  <div v-if="!loading">
    <div class="accommodation">
      <div v-for="(item, key) in acccommodation" :key="key" class="accommodation-wrap">
        <div class="accommodation-title">
          {{ item.title }}
        </div>
        <div class="accommodation-box">
          <ImageSlider3 :images="item.images" :per-view="1"/>
        </div>
        <div class="accommodation-info">
          {{ item.description }}
        </div>
        <div class="accommodation-button" @click="openBooking(item.booking_id)">
          Забронировать
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageSlider3 from "@/components/layout/content/ImageSlider3";
export default {
name: "Accommodation",
  components: {ImageSlider3},
  props: {
    loading: {
      type: Boolean,
      required: true,
    }
  },
  data: () => ({
    acccommodation: {
      houses: {
        title: 'Дом Suite',
        booking_id: '215375',
        description: 'Двухэтажный дом с террасой общей площадью 90 м2. \n' +
            'На первом этаже расположена кухня с обеденной зоной, изолированная спальня с двуспальной кроватью и дополнительным местом, а также совмещенный санузел.\n' +
            'Весь второй этаж занимает просторная спальня с двуспальной кроватью и раскладывающимся диваном',
        images: [
          '/images/new/RF1_0287.jpg',
          '/images/IMG_8134.JPG',
          '/images/IMG_8119.JPG',
          '/images/IMG_8105.JPG',
          '/images/IMG_8180.JPG',
          '/images/IMG_8172.JPG',
          '/images/IMG_8200.JPG'
        ],
      },
      duplex_houses: {
        title: 'Дом Duplex',
        booking_id: '281626',
        description: 'Двухэтажный дом с террасой общей площадью 112 м2.\n' +
            'На первом этаже расположена просторная кухня-гостиная с камином, диваном и обеденной зоной и светлый, совмещенный санузел.\n' +
            'На втором этаже расположены две изолированные спальни с кроватями размера King size и дополнительным местом, а также дополнительный санузел.',
        images: [
          '/images/new/RF1_0001.jpg',
          '/images/new/RF1_0635.jpg',
          '/images/new/RF1_0133.jpg',
          '/images/new/RF1_0712.jpg',
          '/images/new/RF1_0721 (1).jpg',
          '/images/new/RF1_0726.jpg',
          '/images/new/RF1_0735.jpg',
          '/images/new/RF1_0738.jpg',
          '/images/new/RF1_0743 (1).jpg',
          '/images/new/RF1_0672.jpg',
          '/images/new/RF1_0681.jpg',
          '/images/new/RF1_0759.jpg',
          '/images/new/RF1_0787.jpg',
        ],
      },
      rooms: {
        title: 'Номер Люкс',
        booking_id: '215374',
        description: 'Комфортабельный двухкомнатный номер 25м2 с раздельным размещением для четверых человек и мягкой зоной, с видом на вершины Софийского хребта',
        images: [
          '/images/new/RF1_8648.jpg',
          '/images/accommodation/room1.jpg',
          '/images/IMG_6732.JPG',
          '/images/IMG_6742.JPG',
          '/images/IMG_6735.JPG',
        ]
      },
    },
  }),
  methods: {
    openBooking(room){
      const now = new Date();
      console.log(now);
      const dfrom = new Date();
      dfrom.setDate(now.getDate()+1);
      console.log(dfrom);
      const dto = new Date();
      dto.setDate(dfrom.getDate()+1);
      console.log(dto);
      const q = {
        dfrom: this.formatDate(dfrom),
        dto: this.formatDate(dto),
        uid: '901f17e2-e78a-4708-9f6a-1f3aa654c3f6',
        lang: 'ru',
        adults: '1',
        onlyrooms: room,
      }
      this.$router.push({name: 'Booking', query: q});

    },
    formatDate(d){
      console.log(d);
      return `${d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`;
    }
  }
}
</script>

<style scoped>
.accommodation{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}
.accommodation-title{
  padding: 10px 1rem;
  font-size: 14pt;
  font-weight: bold;
  border-bottom: 1px solid #484848;
 }
.accommodation-wrap{
  width: calc(33% - 5px);
  background-color: #fff;
  border: 1px solid #484848;
  display: flex;
  flex-direction: column;
}
.accommodation-box{
  height: 220px;
  box-sizing: border-box;
  max-width: 100%;
  /*padding: 0 3rem;*/
  border-bottom: 1px solid #484848;
}
.accommodation-info{
  padding: 1rem 2rem;
  text-align: center;
  flex-grow: 1;
 }
.accommodation-button{
  background-color: #ffc629;
  color: #484848;
  font-weight: bold;
  margin: 10px;
  border-radius: 4px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
@media only screen and (max-width: 480px){
  .accommodation-wrap{
    width: 100%;
  }
}
</style>
