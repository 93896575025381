<template>
  <div class="extra">
    <div class="extra-banner-wrap">
      <div class="extra-banner">
          <div class="extra-banner-logo">
            <img src="https://горыроссии.рф/award/img/rumount.svg"/>
          </div>
          <div class="extra-banner-title">
            Национальная премия «Горы России»
          </div>
        <div>
          <p class="extra-banner-text">
            Просим вас <a href=" https://горыроссии.рф/award/vote?utm_source=landing" alt="Горы России" target="_blank">проголосовать</a> за наш молодой, активный и перспективный Архыз/парк-отель SUN VILLAGE Arkhyz.
           <br/> 👉 в номинациях:
            <ul>
              <li>
                ✅ <b>Лучший в России горнолыжный отель</b>
              </li>
              <li>
                ✅ <b>Лучший в России отель горнолыжного курорта Северо-Кавказского ФО </b>
              </li>
              <li>
                и не забываем проголосовать<br/>
                ✅ за <b>«Лучший горнолыжный курорт»</b> в котором участвует наш Всесезонный курорт Архыз.
              </li>
            </ul>

            <b>Поддержите нас пожалуйста, проголосуйте за наш отель, важен каждый голос!</b><br/><br/>
            Голосование проходит с авторизацией через электронную почту, не забудьте проверить папку спам, чтобы подтвердить свой голос.<br/><br/>
            Благодарим всех неравнодушных за поддержку 🫶

          </p>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
// import ImageSlider3 from "../layout/content/ImageSlider3";

import ny1 from '@/assets/ny2024_1.jpg';
import ny2 from '@/assets/ny2024_2.jpg';
import ny3 from '@/assets/ny2024_3.jpg';

export default {
  name: "ExtraBanner",
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: i});
    }
  },
  data: () => ({
    images: [
      {k: 'ny3', src: ny3, alt: 'welcome'},
      {k: 'ny2', src: ny2, alt: 'ny'},
      {k: 'ny1', src: ny1, alt: 'menu'},
    ],
  }),
}
</script>

<style scoped>
.extra{
  /*padding: 1rem 0;*/
}
.extra-banner-wrap{
  padding: 2rem;
  background-color: #fff;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.extra-banner{
  height: 100%;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
.extra-banner-logo{
  max-width: 300px;
}
.extra-banner-title{
  font-family: Helvetica, sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #004664;
  text-transform: uppercase;
  line-height: 1.1;
}
.extra-banner-text{
  font-size: 13pt;
}
.extra-banner-text a{
  text-decoration: underline;
}
.extra-banner-text ul{
  list-style: none;
}
.extra-banner-text ul li{
  padding: 8px 0;
}

@media only screen and (max-width: 480px) {

  .extra-banner-wrap{
    height: auto;
    padding: 1rem 2rem;
  }
  .extra-banner{
    flex-wrap: wrap;
    gap: 12px;
  }
  .extra-banner-image{
    min-height: 350px;
    width: 100%;
  }
}
</style>