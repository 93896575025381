<template>
  <Page title='"Ресторан"'>
    <template v-slot:content>
      <div class="cafe">
        <p class="text">
          В ресторанно-развлекательном комплексе SUN VILLAGE Arkhyz открыт ресторан современной кавказской кухни.<br/>
          Время работы ресторана с 13:00 до 00:00, завтраки проходят с 9:00 до 11:00.<br/>
          Для гостей не проживающих в отеле, завтрак 850р.<br/>
          Так же работает служба доставки. Стоимость доставки от 300р.<br/>
          Бронь столов и доставка по телефону: <a href="tel:+79283575766">+7 (928) 357-57-66</a>
        </p>
        <div class="images">
          <div class="image" v-for="i in images" :key="i.src" @click="openImage(i.src)">
            <img :src="i.src" :alt="i.alt"/>
          </div>
        </div>
      </div>
      <div></div>
    </template>
  </Page>
</template>

<script>
import Page from "../layout/Page";

import cafe from '@/assets/cafe.jpg';
import menu from '@/assets/menu.jpg';

export default {
  name: "Restaurant",
  components: { Page },
  data: () => ({
    images: [
      { id: 2, src: menu, alt: 'Меню'},
      { id: 3, src: cafe, alt: 'Фото кафе'}
    ]
  }),
  methods: {
    openImage(i){
      this.$root.$emit('popup', {type: 'image', content: i});
    }
  },
}
</script>

<style scoped>
.text{
  font-size: 12pt;
}
.images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1rem;
  padding-top: 1rem;
}
.image{
  max-width: 350px;
  cursor: pointer;
}
</style>
