<template>
  <div class="footer">
    <div class="footer-content">
      <PartnerBlock></PartnerBlock>
      <CorpBlock></CorpBlock>
    </div>
  </div>
</template>

<script>
import CorpBlock from "@/components/layout/footer/CorpBlock";
import PartnerBlock from "./PartnerBlock";
export default {
  name: "Footer",
  components: {PartnerBlock, CorpBlock }
}
</script>

<style scoped>
.footer{
  position: relative;
  width: 100%;
  background-color: #484848;
  padding-bottom: 4rem;
  padding-top: 1rem;
}
.footer:before{
  content: "";
  position: absolute;
  top: 0; bottom: 0; right: 0; left: 0;
  background-color: rgba(30,30,30,0.6);
  pointer-events: none;
  z-index: 0;
}
.footer-content{
  position: relative;
  padding: 0 6rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 100;
}
.footer-content > div{
  flex: 1;
}
@media (max-width: 960px) {
  .footer-content{
    padding: 0 4rem;
  }
}
@media (max-width: 480px) {
  .content .footer{
    padding-bottom: 5rem!important;
  }
  .footer-content{
    padding: 1rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
}
</style>