<template>
<div class="partners">
<!--  <div class="block-title">-->
<!--    Партнеры-->
<!--  </div>-->
  <div class="partners__list">
    <div class="partner" v-for="(item, key) in partners" :class="{'inline': item.label}" :style="item.style" :key="key">
     <div class="img-wrap"><a :href="item.href" target="_blank"><img :src="item.logo" alt="key"/></a></div>
     <div v-if="item.label"><span class="label">{{ item.label }}</span></div>
    </div>
  </div>

</div>
</template>

<script>
import arkhyzClubLogo from '@/assets/arkhyz-club.png';
import multiTourLogo from '@/assets/multitour.svg';
import aleanLogo from '@/assets/alean-logo.svg';
import tvilLogo from '@/assets/tvil.svg';
import hotels101Logo from '@/assets/101hotels.svg';
import yandexLogo from '@/assets/yandexTravel.svg';
import jetLogo from '@/assets/jetLogo.png'
import coralLogo from '@/assets/coralLogo.svg'

// import arkhyzTLogo from '@/assets/arkhyz-travel-logo.png';
// import ostrovokLogo from '@/assets/ostrovok.svg';

export default {
  name: "PartnerBlock",
  data: () => ({
    partners: {
      yandex: { logo: yandexLogo, href: 'https://travel.yandex.ru/', label: 'Путешествия'},
      hotel101: { logo: hotels101Logo, href: 'https://101hotels.com/'},
      arkhyzClub: { logo: arkhyzClubLogo, href: 'https://arkhyz.club' },
      multiTour: { logo: multiTourLogo, href: 'https://www.multitour.ru/', style: 'margin-top: 15px'},
      tvil: { logo: tvilLogo, href: 'https://tvil.ru', style: 'margin-top: 15px'},
      jet: { logo: jetLogo, href: 'https://jettravel.ru'},
      coral: { logo: coralLogo, href: 'https://www.coral.ru/'},
      alean: {logo: aleanLogo, href: 'https://www.alean.ru/'},

      // arkhyztravel: {logo: arkhyzTLogo, href: 'http://arkhyz.travel/'},
      // ostrovok: { logo: ostrovokLogo, href: 'https://ostrovok.ru/' },
      // extreme: {},
    }
  }),
}
</script>

<style scoped>
.partners{
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}
.partners__list{
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 8px;
  align-items: center;
}
.partner{
  max-height: 1.5rem;
  margin: .2rem 0;
  width: 30%;
  text-align: center;
  display: flex;
  align-items: center;
}
.partner.inline{
  flex-direction: row;
}
.partner img{
  height: 100%;
  max-height: 50px;
}
.partner .img-wrap{
  max-height: 100%;
  flex-grow: 1;
}
.block-title{
  margin: 1rem 0;
  color: #fff;
  font-size: 18pt
}
.label{
  font-weight: bold;
  font-size: 8pt;
  margin-left: 5px;
  color: #fff;
}
@media (max-width: 480px) {
  .partners{
    width: 100%;
  }
  .block-title{
    text-align: center;
  }
  .partner{
    position: relative;
    height: 5rem;
  }
  .partners__list{
    gap: 12px;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
  }
  .partner img{
    max-height: 50px;
    min-width: 25px;
  }
}
</style>
