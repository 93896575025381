<template>
  <div>
    <div v-if="windowWidth > 480 || !isMobile" class="nav-wrap">
      <div class="menu">
        <ul class="nav">
          <li v-for="item in menuLeft" :key="item.name" @click="goTo(item)">
              {{ item.title }}
          </li>
        </ul>
        <div class="spacer"></div>
        <ul class="nav">
          <li v-for="item in menuRight" :key="item.name" @click="goTo(item)">
              {{ item.title }}
          </li>
        </ul>
      </div>
      <div class="submenu">
        <div class="submenu-bars" @click="toggleMenu">
         <transition name="fade" mode="out-in">
           <font-awesome-icon key="1" v-if="!submenuOpen"  class="icon fa-2x" :icon="['fa', 'bars']"></font-awesome-icon>
           <font-awesome-icon key="2" v-else class="icon fa-2x" :icon="['fa', 'times']"></font-awesome-icon>
         </transition>
        </div>
        <transition name="slide">
          <div v-if="submenuOpen" class="submenu-dropdown">
            <ul class="sub-nav">
              <router-link  v-for="item in menuExtra" :key="item.name" :to="'/'+item.name">
                <li class="submenu-item">
                  {{ item.title }}
                </li>
              </router-link>
            </ul>
          </div>
        </transition>
      </div>
    </div>
    <div v-else class="mobileMenu">
      <div class="menu-bars" v-if="!menuOpen" @click="menuOpen = true">
        <font-awesome-icon class="icon fa-2x" :icon="['fa', 'bars']"></font-awesome-icon>
      </div>
     <transition name="slide">
       <div v-if="menuOpen" class="menu-items">
         <div class="menu-close" @click="menuOpen = false">
           <font-awesome-icon class="icon fa-3x" :icon="['fa', 'times']"></font-awesome-icon>
         </div>
         <ul class="nav-mobile">
           <a v-for="item in menuItems" :key="item.name">
             <li v-if="!item.link" @click="goTo(item)">
               {{ item.title }}
             </li>
             <router-link v-else @click="menuOpen = false" :to="'/'+item.name" tag="li">
                 {{ item.title }}
             </router-link>
           </a>
         </ul>
       </div>
     </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    goTo(item){
      if(this.isMobile){
        this.menuOpen = false;
      }
      if(item.link){
        this.$router.push(`/${item.name}`);
      } else {
        if (this.$route.name === 'home') {
          history.pushState({}, null, item.route);
          this.$root.$emit('goToPage', item);
        } else {
          this.$router.push(`/home/${item.route}`);
        }
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleMenu(){
      this.submenuOpen = !this.submenuOpen;
    }
  },
  mounted() {
    this.$root.$on('closeMenu', () => {
      this.submenuOpen = false;
      this.menuOpen = false;
    });
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    menuItems(){
      return this.menuLeft.concat(this.menuRight.concat(this.menuExtra));
    }
  },
  data: () => ({
    windowWidth: window.innerWidth,
    menuLeft: [
      {name:"about", title: "О Нас", route: "about"},
      {name:"accommodation", title: "Размещение", route: "accommodation"},
      {name:"sauna", title: "SPA Зона", route: "sauna"},
    ],
    menuRight: [
      { name:"restaurant", title:"\"Ресторан\"", link: true, },
      { name: 'events', title: "Афиша", link: true },
      { name:"contact", title:"Контакты", route: "contact" }
    ],
    menuExtra: [
      // { name: "services", title: "Услуги", link: true },
      { name: "excursions", title: "Экскурсии", link: true },
      { name: "press", title: "Пресса", link: true },
      // { name:"guests", title: "Гости", link: true },
      { name: 'corporate', title: "Корпоративным клиентам", link: true },
    ],
    menuOpen: false,
    submenuOpen: false,
  }),
}
</script>

<style scoped>
.menu {
  position: relative;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
}
.menu ul.nav {
  display: flex;
  flex-direction: row;
  list-style: none;
  height: 100%;
  padding: 0 30px;
  text-align: center;
}
.submenu li, .menu li {
  min-width: 90px;
  height: 100%;
  padding: 25px 15px;
  display: inline-block;
  font-family: sans-serif;
  cursor: pointer;
  font-size: 14pt;
}
.menu li{
  color: #eee;
}
.submenu li{
  color: #eee;
}
.menu li{
  white-space: nowrap;
}
.menu li:hover {
  color: #ffc629;
}
.nav-wrap{
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.menu{
  width: 100%;
  overflow-y: scroll;
}
.menu-bars {
  padding: .1em 1em;
}
.menu-close {
  text-align: right;
  padding: 1em;
}
.menu-items {
  position: fixed;
  top: 40px;
  left: 40%;
  height: 100%;
  width: 60%;
  background-color: rgba(0,0,0,0.9);
}

.nav-mobile {
  list-style: none;
  font-size: 16pt;
  line-height: 1.2;
  padding: 0;
}
.nav-mobile li:first-child{
  border-top: 1px solid #bfbfbf;
}
.nav-mobile li{
  border-bottom: 1px solid #bfbfbf;
  padding: .4em 0 .4em 2rem;
}
.nav-mobile li:hover, .nav-mobile li:focus{
  background-color: rgba(255,255,255,0.1);
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to  {
  transform: translate(200%, 0);
}

.submenu{
  align-items: center;
  right: 1rem;
  display: flex;
  height: 100%;
}
.submenu-bars{
  cursor: pointer;
  width: 40px;
}
.submenu-dropdown{
  position: absolute;
  top: 100%;
  width: 250px;
  height: calc(100vh - 100%);
  right: 0;
  background-color: #222;
  padding-top: 15px;
}
.submenu-dropdown li:hover{
  background-color: #ffc629;
  color: #222;
}
.sub-nav{
  color: #ddd;
  font-size: 14pt;
  padding-left: 0;
}
.submenu .submenu-item{
  text-align: right;
  padding: 10px 20px;
  /*border-bottom: 2px solid #d0d0d0;*/
  width: 100%;
}
.submenu .sub-nav a:last-child .submenu-item{
  border: unset;
}


.fade-enter-active,
.fade-leave-active {
  transition: opacity .15s
}

.fade-enter,
.fade-leave-to {
  opacity: 10%
}
</style>
